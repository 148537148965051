import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import * as s from "./integrations.module.css";

const IntegrationsAlter = () => {
  return (
    <>
      <section className={`${s.integrationsBlock} ${s.integrationsBlockAlter}`}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 my-5">
              <div className={s.integrationsPos}>
                <h3 className={`${s.integrationsSubtitle}`}>
                  Delivery Solution Provider
                </h3>
                <div className="d-flex flex-wrap">
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="doordash"
                      src="../../images/integrations/doordash.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="ubereats"
                      src="../../images/integrations/ubereats.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="uberforbusiness"
                      src="../../images/integrations/uberforbusiness.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} py d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="nash"
                      src="../../images/integrations/nash.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="cartwheel"
                      src="../../images/integrations/cartwheel.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 my-5">
              <div className={s.integrationsPos}>
                <h3
                  className={`${s.integrationsSubtitle} text-center text-lg-left`}
                >
                  POS Integrations
                </h3>
                <div className="d-flex flex-wrap justify-content-md-start">
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="clover"
                      src="../../images/integrations/clover.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Clover</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="square"
                      src="../../images/integrations/square.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Square</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="aloha"
                      src="../../images/integrations/aloha.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Aloha</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="brink"
                      src="../../images/integrations/brink.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Brink</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="dinerware"
                      src="../../images/integrations/dinerware.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Dinerware</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="micros_3700"
                      src="../../images/integrations/micros_3700.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Micros 3700</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="oracle_micros"
                      src="../../images/integrations/oracle_micros.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Micros Simphony FE</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="oracle_micros"
                      src="../../images/integrations/oracle_micros.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Micros Simphony</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="positouch"
                      src="../../images/integrations/positouch.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>POSitouch</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="squirrel"
                      src="../../images/integrations/squirrel.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Squirrel</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="xpient"
                      src="../../images/integrations/xpient.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>XPIENT</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="lavu"
                      src="../../images/integrations/lavu.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Lavu</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="lightspeed"
                      src="../../images/integrations/lightspeed.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Lightspeed</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="maitred"
                      src="../../images/integrations/maitred.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Maitre’D</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="cloudconnect"
                      src="../../images/integrations/cloudconnect.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>NCR Cloud Connect</span>
                  </div>

                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="toast"
                      src="../../images/integrations/toast.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Toast</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="thrive"
                      src="../../images/integrations/thrive.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Thrive</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="total_touch"
                      src="../../images/integrations/total_touch.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Total Touch</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="arrow_pos_pom"
                      src="../../images/integrations/arrow_pos_pom.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Arrow POS</span>
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="precision"
                      src="../../images/integrations/precision.png"
                      className="w-auto mw-100 mh-100"
                    />
                    <span>Precision POS</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 my-5">
              <div className={s.integrationsPos}>
                <h3 className={`${s.integrationsSubtitle}`}>
                  Delivery Applications
                </h3>
                <div className="d-flex flex-wrap">
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="grubhub"
                      src="../../images/integrations/grubhub.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="doordash"
                      src="../../images/integrations/doordash.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="ubereats"
                      src="../../images/integrations/ubereats.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="postmates"
                      src="../../images/integrations/postmates.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="delivery_com"
                      src="../../images/integrations/delivery_com.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="eatstreet"
                      src="../../images/integrations/eatstreet.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} ${s.soon} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="slice"
                      src="../../images/integrations/slice.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 my-5">
              <div className={s.integrationsPos}>
                <h3 className={`${s.integrationsSubtitle}`}>
                  Directory Partners
                </h3>
                <div className="d-flex flex-wrap">
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="googlemybusiness"
                      src="../../images/integrations/googlemybusiness.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="yelp"
                      src="../../images/integrations/yelp.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="yahoo"
                      src="../../images/integrations/yahoo.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                  <div
                    className={`${s.integrationsItemOuter} d-flex align-items-center justify-content-center`}
                  >
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="bing"
                      src="../../images/integrations/bing.png"
                      className="w-auto mw-100 mh-100"
                    />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationsAlter;
