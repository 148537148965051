import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContactForm from "./../components/contactForm/form";
import Faq from "./../components/faq/faq";
import Footer from "./../components/footer/footer";
import Header from "./../components/header/header";
import IntegrationsAlter from "./../components/integrations/integrationsAlter";
import Seo from "./../components/seo";
import Top from "./../components/top/top";
import Banner from './../components/banner/banner';
import bannerBG from "../images/IntegrationsPage/banner.png";
import gradientBg from "../images/IntegrationsPage/gradient-pink-bg.png";
// markup
const IntegrationsPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 3,
    scrollToForm: scrollToForm,
    title: (
      <>
        Our growing network of<br /> Integration Partners
      </>
    ),
    content: (
      <>
        We partner with major POS and Delivery service providers to simplify online ordering
        for all restaurants.
      </>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering product app"
        src="../images/IntegrationsPage/Q-commerce.png"
        // src="../images/IntegrationsPage/ordering-app-product.png"
        className="mw-100"
      />
    ),
    hideButton: true,
  };

  const bannerProps = {
    layout: 3,
    bg: bannerBG,
    title: "Want to become a",
    subtitle: "partner?",
    scrollToForm: scrollToForm,
    buttonText: <>Click here &#10142;</>,
  };

  const questions = [
    {
      question: `I do not see my POS/Delivery app listed here. Can Orders.co still integrate it?`,
      answer: `Yes! Simply contact an Orders.co representative and provide them with the service
        name you wish to have integrated. If possible, also providing contact details can expedite
        the process.`,
    },
    {
      question: `We use multiple ticket printers in our restaurant. Does Orders.co support this?`,
      answer: ``,
    },
    {
      question: `How many integrations can I have done on my account?`,
      answer: ``,
    },
    {
      question: `How will orders from multiple virtual stores be displayed in Orders.co?`,
      answer: ``,
    },
    {
      question: `How can we integrate an Orders.co ordering site into our existing website?`,
      answer: ``,
    },
  ];

  return (
    <>
      <Seo
        title={"Home"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Banner {...bannerProps} />
        <div
          className="bg-desktop"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
          }}
        >
          <IntegrationsAlter scrollToForm={scrollToForm} />
        </div>
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Faq
            items={questions}
            title={
              <span style={{ fontSize: "42.7px" }}>
                Frequently Asked Questions
              </span>
            }
          />
          <div ref={formRef}>
            <ContactForm
              titleStyle="small"
              title={
                <span style={{ fontSize: "45px", lineHeight: "68px" }}>
                  Join the<br className="d-none d-md-inline-block"/> Orders.co<br/>Network
                </span>
              }
            ></ContactForm>
          </div>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "integrations" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default IntegrationsPage;
