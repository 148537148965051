// extracted by mini-css-extract-plugin
export var integrationsBlock = "integrations-module--integrations-block--696b4";
export var integrationsBlockAlter = "integrations-module--integrations-block-alter--cbb13";
export var integrationsDescription = "integrations-module--integrations-description--5b951";
export var integrationsItemOuter = "integrations-module--integrations-item-outer--113bf";
export var integrationsOnline = "integrations-module--integrations-online--3c3e4";
export var integrationsPos = "integrations-module--integrations-pos--79dd2";
export var integrationsSubtitle = "integrations-module--integrations-subtitle--7f185";
export var integrationsTitle = "integrations-module--integrations-title--d7ed9";
export var soon = "integrations-module--soon--48fa2";
export var support = "integrations-module--support--b7131";